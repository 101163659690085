<template>
  <div>
    <div v-if="loadingPage">
      <div class="spinning-loader"></div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="!loadingPage && !loadingEmployeeData" class="page-block-item">

        <div v-if="!showMainPageArea && loadingEmployeeData" class="dataentrydiv">
          <div class="spinning-loader"></div>
        </div>

        <div v-if="showMainPageArea">
          <div v-if="vaccinations && vaccinations.length > 0 && !extraShot">
            <div class="dataentrydiv margin-bottom-25">
              <div class="prior-input">
                <span>
                  <div class="input-records-title">
                    {{ $t('hepProgram_vaxPage_vaccinationRecords') }}
                  </div>
                </span>
                <table v-for="vaccination in vaccinations" v-bind:key="vaccination.id"
                  class="vaccination-records-table">
                  <tr v-for='(item, key) in SHOT_BY_INDEX' :key='key'
                    :set="SHOTS_INPUT[SHOT_BY_INDEX[key]] = vaccination[item]">
                    <td v-if="vaccination[item]"
                      style="text-align: center;">
                      {{ $t(`hepProgram_vaxPage_dose`) }} {{ key }} -
                      {{ getShotInputDate(key).date }}
                    </td>
                  </tr>
                </table>
                <button class="delete-btn" @click="deleteRecord(vaccinations[0].firstDose.date)">
                  {{ $t('deleteRecords') }}
                </button>
              </div>
            </div>
            <div v-if="showBoosterShotButton()" class="dataentrydiv margin-bottom-25">
              <span @click="addExtraShot" class="button w-button uppercase">
                {{ $t('uploadHepVaccinationPage_addBosterDose') }}
              </span>
            </div>

            <div>
              <div class="general-text text-justify">
                <div >{{ $t('ifVaccinationIncorrectUser') }}</div>
              </div>
            </div>

          </div>

          <div v-else class="dataentrydiv">
            <div class="general-text container hep-description">
              {{ $t('hepProgram_vaxPage_pageDescription') }}
            </div>

            <multiselect v-model="dosisNumber" :options="[1, 2, 3]"
                :searchable="true" :allow-empty="false" :close-on-select="true"
                :show-labels="false" :placeholder="$t('hepProgram_vaxPage_selectDosis')"
                class="dosis-selection margin-bottom-15"
            >
            </multiselect>

            <div class="input-label margin-bottom-15">
              {{ $t(`hepProgram_vaxPage_informDates`) }}
            </div>

            <div v-for='item in dosisNumber' :key='item' class="dataentrydiv">
              <div class="margin-bottom-5">
                {{ $t(`hepProgram_vaxPage_dose`) }} {{ item }}
              </div>
              <div class="margin-bottom-5" >
                <div class="daterow margin-bottom-5">
                  <div class="datepick">
                    <date-pick v-model="SHOTS_INPUT[SHOT_BY_INDEX[item]]"
                      :isDateDisabled="(date) => {
                        return isDaysBeforeLastDose(date, item) || disableExtraShotInput(item);
                      }">
                      <template v-slot:default="{toggle, inputValue}">
                        <button @click="toggle" class="datepicker-btn">
                          {{ inputValue || $t('selectDate') }}
                        </button>
                      </template>
                    </date-pick>
                  </div>
                </div>
              </div>
            </div>

            <div class="dataentrydiv margin-bottom-25 margin-top-25">
              <div class="input-label margin-bottom-15">
                {{ $t('hepProgram_vaxPage_uploadVax') }}
              </div>

              <vue-dropzone ref="uploadVueDropzone" id="dropzoneId"
                :options="dropzoneOptions" :useCustomSlot="true"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-sending="uploadingImage"
                @vdropzone-error="errorUploading"
                @vdropzone-queue-complete="uploadCompleted">
                <div class="dropzone-custom-content">
                  <img src="@/assets/images/upload.svg" loading="lazy" alt="" class="uploadicon">
                  <div>{{ $t('uploadAreaInfo') }}</div>
                </div>
              </vue-dropzone>
            </div>

            <div class="dataentrydiv" style="width: 100%">
              <span v-if="!submitting" @click="submitForm" class="button w-button">
                {{ $t('hepProgram_vaxPage_saveYourChanges') }}
              </span>
              <span v-else class="button w-button">
                <div class="lds-loader"><div></div><div></div><div></div></div>
              </span>
            </div>

            <p class="general-text margin-top-25">
              {{ $t('hepProgram_vaxPage_submitConsent') }}
            </p>

          </div>
        </div>

        <div class="dataentrydiv new-section-page">
          <privacy-policy />
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePick from 'vue-date-pick';
import vueDropzone from 'vue2-dropzone';
import { uuid } from 'vue-uuid';
import Path from 'path';

import AuthApi from '@/api/auth.api';
import VaccineApi from '@/api/vaccine.api';
import EmployeeApi from '@/api/employee.api';
import UploadApi from '@/api/upload.api';
import Utils from '@/utils/utils';

import 'vue-date-pick/dist/vueDatePick.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

function mapToSelect(label, value) {
  return { label, value };
}

const CLIENT = Utils.getClient();

const OTHER_BRAND_HEP_VAX_ID = 5;

export default {
  name: 'HepProgram_UploadVaccination',
  props: ['loggedUser'],

  components: {
    Multiselect,
    DatePick,
    vueDropzone,
  },

  data() {
    return {
      dosisNumber: 1,
      SHOT_BY_INDEX: {
        1: 'firstDose',
        2: 'secondDose',
        3: 'thirdDose',
      },
      SHOTS_INPUT: {},
      NEXT_SHOT: 1,
      loadingPage: true,
      loadingEmployeeData: false,
      showMainPageArea: false,
      vaccines: [],
      vaccineOptions: [],
      selectedEmployee: null,
      vaccinations: [],
      dropzoneOptions: {
        url: UploadApi.getVaccinationHepEndpoint(),
        // capture: 'camera',
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: false,
        addRemoveLinks: true,
        withCredentials: true,
        headers: {
          'x-sub-client': CLIENT,
        },
      },
      uploadedFiles: [],
      uploadHasError: false,
      submitting: false,
      extraShot: false,
    };
  },

  created() {
    this.fetchInitialData();
  },

  methods: {

    isDaysBeforeLastDose(date, current) {
      const currentDate = new Date();
      let isBeforePrevious = false;
      const lastDose = this.getShotInputDate(current - 1);
      if (lastDose) {
        const lastDoseDate = new Date(lastDose);
        lastDoseDate.setDate(lastDoseDate.getDate() + 180);
        isBeforePrevious = date < lastDoseDate;
      }
      return isBeforePrevious || date > currentDate;
    },

    resetForm() {
      this.SHOTS_INPUT = {};
      this.NEXT_SHOT = 1;
      this.uploadedFiles = [];
    },

    fileAdded() {
      const existingFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      Object.keys(existingFiles).forEach((key) => {
        this.$refs.uploadVueDropzone.removeFile(existingFiles[key]);
      });
    },

    fileRemoved() {
      this.uploadedFiles = [];
    },

    uploadingImage(file, xhr, formData) {
      const fileName = `${uuid.v1()}${Path.extname(file.name)}`;
      formData.append('client', CLIENT);
      formData.append('employeeId', this.selectedEmployee.value);
      formData.append('fileName', fileName);
      this.uploadedFiles.push(fileName);
    },

    errorUploading() {
      this.uploadHasError = true;
      this.uploadedFiles = [];
      this.$refs.uploadVueDropzone.removeAllFiles();
    },

    uploadCompleted() {
      if (!this.uploadHasError) {
        // Complete sending data
        this.completeSubmitForm();
      } else {
        this.submitting = false;
        // Display error message
        this.showErroAlert({
          title: this.$t('uploadError'),
          text: this.$t('uploadErrorInst'),
        });
      }
    },

    submitForm() {
      this.submitting = true;
      this.uploadHasError = false;

      if (!this.validateForm()) {
        this.submitting = false;
        return;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (queuedFiles.length) {
        this.$refs.uploadVueDropzone.processQueue();
      } else {
        // Complete sending data
        this.completeSubmitForm();
      }
    },

    async deleteRecord(vaccinationId) {
      const confirmation = await this.$swal({
        title: this.$t('areYouSure'),
        text: this.$t('noRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('deleteConfirm'),
      });
      if (confirmation.isConfirmed) {
        await EmployeeApi.deleteVaccinationHep(this.selectedEmployee.value, vaccinationId);
        this.fetchEmployeeData();
      }
    },

    async completeSubmitForm() {
      const vaccination = {
        clientId: CLIENT,
        employeeId: this.selectedEmployee.value,
        images: [...this.uploadedFiles],
      };

      const maxBooster = Math.max(...Object.keys(this.SHOT_BY_INDEX));
      for (let i = 1; i <= maxBooster; i += 1) {
        if (this.getShotInputDate(i)) {
          vaccination[this.SHOT_BY_INDEX[i]] = {
            date: this.getShotInputDate(i),
            vaccineId: OTHER_BRAND_HEP_VAX_ID,
          };
        }
      }

      try {
        this.submitting = false;

        await EmployeeApi.submitVaccinationHep(this.selectedEmployee.value, vaccination);
        await this.showSuccesslert({
          title: this.$t('success'),
          text: this.$t('submitSuccess'),
        });
        this.extraShot = false;
        this.fetchEmployeeData();
      } catch (e) {
        await this.showErroAlert({
          title: 'Oops..',
          text: this.$t('somethingWentWrong'),
        });
      }
      this.submitting = false;
    },

    validateForm() {
      if (!this.getShotInputDate(1)) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('selectFirstDoseDate'),
        });
        return false;
      }

      for (let i = 2; i <= this.dosisNumber; i += 1) {
        if (!this.getShotInputDate(i)) {
          this.showWarninglert({
            title: 'You\'re missing a dosis date!',
            text: 'Please select a date from the date picker, then try submitting again.',
          });
          return false;
        }
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (this.uploadedFiles.length === 0 && queuedFiles.length === 0) {
        this.showWarninglert({
          title: this.$t('missingCard'),
          text: this.$t('mustUpload'),
        });
        return false;
      }
      return true;
    },

    showErroAlert(message) {
      return this.showAlert({
        ...message,
        icon: 'error',
      });
    },

    showWarninglert(message) {
      return this.showAlert({
        ...message,
        icon: 'warning',
      });
    },

    showSuccesslert(message) {
      return this.showAlert({
        ...message,
        icon: 'success',
      });
    },

    showAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async fetchInitialData() {
      try {
        if (!this.loggedUser.roles?.includes('USER')) {
          this.$router.push({ name: 'AuthErrorPage' });
          return;
        }

        this.selectedEmployee = mapToSelect(this.loggedUser.name, this.loggedUser.id);

        const vaccinesResult = await VaccineApi.getAllHep();
        this.vaccines = vaccinesResult.data;
        this.vaccines.sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });

        this.vaccineOptions = this.vaccines
          .map((vaccine) => mapToSelect(vaccine.name, vaccine.id));

        this.loadingPage = false;
      } catch (e) {
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
        // Should route to error page
      }
    },

    async fetchEmployeeData() {
      this.showMainPageArea = false;
      this.loadingEmployeeData = true;
      this.resetForm();
      try {
        const vaccinationsResult = await EmployeeApi
          .getVaccinationHepList(this.selectedEmployee.value);

        this.vaccinations = vaccinationsResult.data
          .map((vacc) => {
            const vax = { images: vacc.images };
            Object.values(this.SHOT_BY_INDEX).forEach((val) => {
              if (vacc[val]) {
                vax[val] = vacc[val];
              }
            });
            return vax;
          });

        if (this.vaccinations.length > 0) {
          const currVax = vaccinationsResult.data[0];

          if (this.extraShot) {
            Object.values(this.SHOT_BY_INDEX).sort().forEach((elemetValue) => {
              if (currVax[elemetValue]) {
                this.NEXT_SHOT += 1;
                this.SHOTS_INPUT[elemetValue] = currVax[elemetValue].date;
                this.SHOTS_INPUT[`${elemetValue}SelectedVaccine`] = this.vaccineOptions
                  .find((v) => v.value === currVax[elemetValue].vaccineId);
              }
            });
          }

          this.dosisNumber = this.NEXT_SHOT;
        }

        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
      } catch (e) {
        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
        // Should display error
      }
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },

    addExtraShot() {
      this.extraShot = true;
      this.fetchEmployeeData();
    },

    disableExtraShotInput(vaccineIndex) {
      if (this.extraShot
        && this.NEXT_SHOT > vaccineIndex
        && this.getShotInputDate(vaccineIndex)) {
        return true;
      }
      return false;
    },

    getShotInputDate(index) {
      return this.SHOTS_INPUT[this.SHOT_BY_INDEX[index]];
    },

    showBoosterShotButton() {
      const maxBooster = Math.max(...Object.keys(this.SHOT_BY_INDEX));
      return !this.vaccinations
        || !this.vaccinations[0]
        || !this.vaccinations[0][this.SHOT_BY_INDEX[maxBooster]];
    },

  },

  computed: {},

  watch: {
    dosisNumber() {
      const maxShots = Math.max(...Object.keys(this.SHOT_BY_INDEX));
      const nextShot = this.dosisNumber + 1;
      for (let i = nextShot; i <= maxShots; i += 1) {
        delete this.SHOTS_INPUT[this.SHOT_BY_INDEX[i]];
        delete this.SHOTS_INPUT[`${this.SHOT_BY_INDEX[i]}SelectedVaccine`];
      }
    },

    selectedEmployee() {
      if (this.selectedEmployee) {
        this.fetchEmployeeData();
        this.extraShot = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .dz-progress {
    display: none !important;
  }

  /* Date Pick CSS */
  .vdpComponent {
    width: 100%;
  }

  .vdpComponent.vdpWithInput  > button {
    border: 1px solid#e8e8e8;
    border-radius: 8px;
  }

  .vdpCell.today {
    color: red;
  }

  .multiselect__tags {
    font-size: 16px !important;
    border: 1px solid #cccccc;
  }

  .datepicker-btn {
    width: 270px !important;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    align-items: center !important;
    display: inline-grid !important;
  }

  @media screen and (max-width: 479px) {
    .daterow .datepicker-btn {
      margin-top: 15px !important;
    }

    .datepicker-btn {
      width: 100% !important;
    }
  }

</style>
<style scoped>
  /* Animation CSS */
 .fade-enter, .slide-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.25s ease-out;
  }

  .fade-leave-active {
    transition: opacity 0.25s ease-out;
    opacity: 0;
  }

  .slide-enter-active {
    animation: slide-in 0.25s ease-out forwards;
    transition: opacity 0.25s;
  }

  @keyframes slide-in {
    from {
      transform: translateX(20px);
    }
    to {
      transform: rotate(0);
    }
  }

  /* Submit loader */

  .lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    border: 1px solid #5031D0;
  }
  .lds-loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  /* Vue Dropzone CSS */
  .vue-dropzone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #97D4FF;
    border-radius: 10px;
    background-color: #E8F8FF;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #4D497E;
    font-size: 22px;
    padding: 100px;
  }

  .vue-dropzone:hover {
    background-color: rgba(63, 119, 225, 0.28);
  }

  /* Multi Select CSS */
  .multiselect {
    width: 300px;
    min-width: 300px;
    font-weight: 400;
  }

  .multiselect__option--selected {
    font-weight: 400;
  }

  /* Page CSS */

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .underline-link {
    cursor:pointer;
    text-decoration: none;
    text-decoration: underline;
  }

  .nav-buttons {
    padding-top: 0px !important;
  }

  .uploadicon {
    width: 40px;
    margin-bottom: 25px;
  }

  .new-section-page {
    margin-top: 25px;
  }

  .herolist {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .list-item {
    margin-bottom: 15px;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
  }

  .daterow-nav-buttons {
    justify-content: center;
  }

  .datepick {
    text-align: center;
  }

  .employee-label {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }

  .input-records-title {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .vax-count {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }

  .prior-input {
    width: 100%;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }

  .prior-input span {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3C3C3C;
  }

  .prior-input table {
    margin: 0;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;

  }

  .prior-input td {
    text-align: center;
    max-width: 100px;
    width: 50%;
    height: 20px;
    padding-bottom: 15px;
  }

  .delete-btn {
    width: 150px;
    height: 30px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .delete-btn:hover {
    background-color: #5031D0;
    color: #FFFFFF;
  }

  .deletefirtsdose-btn {
    width: 200px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-bottom: 15px;
    height: 35px;
  }

  .dropzone-custom-content {
    line-height: 27px;
  }

  .admin-screen-warn {
    background: #ECECEC;
    border-radius: 8px;
    padding: 15px 25px;
  }

  .hep-description {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    /* Vue Dropzone CSS */
    .vue-dropzone  {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      padding: 0px;
    }

    /* Multi Select CSS */
    .multiselect {
      width: 100%;
      min-width: 0px;
    }

    /* Page CSS */
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .datepick {
      width: 100%;
    }

  }

</style>
